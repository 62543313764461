<template>
  <Layout :breadcrumb-items="breadcrumbItems">
    <BCard no-body class="main-card no-border-top-left mb-3">
      <BCardBody>
        <div v-if="isLoading" class="text-center my-4">
          <div class="mb-4">
            <BSpinner class="align-middle" />
          </div>
          <h5>Λήψη δεδομένων...</h5>
        </div>

        <div v-else class="mb-3">
          <div class="mt-3 mb-5 d-flex justify-content-between">
            <BButton variant="primary" size="sm" @click="handleGetPrev">
              <BIcon icon="chevron-compact-left" />
            </BButton>
            <div class="text-center">
              <h3 class="font-weight-medium text-body text-center mb-3">
                {{ title }}
              </h3>
              <h5 class="text-center text-muted">
                {{ subTitle }}
              </h5>
            </div>
            <BButton variant="primary" size="sm" @click="handleGetNext">
              <BIcon icon="chevron-compact-right" />
            </BButton>
          </div>
          <div>
            <BarChart :options="chartOptions" :chart-data="chartData" />
          </div>
        </div>
      </BCardBody>
    </BCard>

    <BCard border-variant="secondary" no-body>
      <BCardHeader>
        <BCardTitle class="mb-0" title-tag="h5">
          Στατιστικά Τύπων Παραγγελιών
        </BCardTitle>
      </BCardHeader>

      <BCardBody>
        <BRow class="mb-5">
          <BCol>
            <PieGraph
              :chart-data="checkoutMethodsChartData"
              :options="pieOptions"
              :height="200"
            />
          </BCol>
          <BCol>
            <PieGraph
              :chart-data="sendMethodsChartData"
              :options="pieOptions"
              :height="200"
            />
          </BCol>
        </BRow>

        <BRow class="mb-5">
          <BCol>
            <PieGraph
              :chart-data="orderMethodsChartData"
              :options="pieOptions"
              :height="200"
            />
          </BCol>
          <BCol>
            <PieGraph
              :chart-data="orderTypesChartData"
              :options="pieOptions"
              :height="200"
            />
          </BCol>
        </BRow>
      </BCardBody>
    </BCard>
  </Layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

import BarChart from '@/components/BarChart.vue';
import PieGraph from '@/components/PieGraph.vue';
import request from '@/utils/request';
import Layout from './components/StatsLayout';

@Component({
  components: {
    BarChart,
    PieGraph,
    Layout,
  },
})
export default class StatsOrders extends Vue {
  productsCount = 0;
  categoriesCount = 0;
  ordersCount = 0;
  customersCount = 0;
  monthlyData = {};
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Στατιστικά',
      to: { name: 'stats.orders' },
    },
    {
      text: 'Παραγγελίες',
      active: true,
    },
  ];
  pieOptions = {
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          return `${data.labels[tooltipItem.index]}: ${
            data.datasets[0].data[tooltipItem.index]
          }%`;
        },
      },
    },
    plugins: {
      datalabels: {
        color: '#fff',
        formatter(value) {
          return `${value}%`;
        },
      },
    },
  };
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        title() {
          return 'Τζίρος';
        },
        label(tooltipItem, data) {
          return `${data.datasets[0].data[tooltipItem.index]} €`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            offsetGridLines: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            callback(value) {
              if (value % 1 === 0) {
                return value;
              }

              return null;
            },
          },
        },
      ],
    },
  };

  selectedRange = {
    start: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end: moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
  };
  chartData = {};
  checkoutMethodsChartData = {};
  sendMethodsChartData = {};
  orderMethodsChartData = {};
  orderTypesChartData = {};

  async getEarnings() {
    try {
      const { data } = await request.get('/stats/earnings', {
        params: this.selectedRange,
      });
      this.monthlyData = data;
      this.setChartData(data);
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα δεδομένα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }

  handleGetPrev() {
    const { start, end } = this.selectedRange;

    this.selectedRange = {
      start: moment(start)
        .subtract(1, 'months')
        .format('YYYY-MM-DD'),
      end: moment(end)
        .subtract(1, 'months')
        .format('YYYY-MM-DD'),
    };
    this.getEarnings();
  }

  handleGetNext() {
    const { start, end } = this.selectedRange;

    this.selectedRange = {
      start: moment(start)
        .add(1, 'months')
        .format('YYYY-MM-DD'),
      end: moment(end)
        .add(1, 'months')
        .format('YYYY-MM-DD'),
    };
    this.getEarnings();
  }

  setChartData(earnings) {
    const { start, end } = this.selectedRange;

    this.chartData = {
      labels: earnings.labels,
      datasets: [
        {
          label: `Τζίρος (${moment(start).format('DD-MM-YYYY')} - ${moment(
            end,
          ).format('DD-MM-YYYY')})`,
          backgroundColor: '#086DE1',
          data: earnings.earnings,
          options: {
            tooltips: {
              callbacks: {
                title(tooltipItem, data) {
                  return moment(data.datasets[0].label).format('DD-MM-YYYY');
                },
                label(tooltipItem, data) {
                  return `${
                    data.datasets[0].data[tooltipItem.index]
                  } παραγγελίες`;
                },
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    offsetGridLines: true,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback(value) {
                      if (value % 1 === 0) {
                        return value;
                      }

                      return null;
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    };
  }

  get title() {
    return moment(this.selectedRange.start)
      .locale('el')
      .format('MMMM YYYY');
  }

  get subTitle() {
    return `Παραγγελίες: ${
      this.monthlyData.ordersCount
    } - Τζίρος: ${this.$options.filters.money(
      this.monthlyData.ordersRevenueCount,
    )}`;
  }

  async created() {
    try {
      this.isLoading = true;
      const [
        counters,
        earnings,
        checkoutMethods,
        sendMethods,
        orderMethods,
        orderTypes,
      ] = await Promise.all([
        request.get('/stats/counters'),
        request.get('/stats/earnings', {
          params: this.selectedRange,
        }),
        request.get('/stats/checkout-methods'),
        request.get('/stats/send-methods'),
        request.get('/stats/order-methods'),
        request.get('/stats/order-types'),
      ]);
      this.monthlyData = earnings.data;
      this.categoriesCount = counters.data.categoriesCount;
      this.customersCount = counters.data.customersCount;
      this.productsCount = counters.data.productsCount;
      this.ordersCount = counters.data.ordersCount;
      this.setChartData(earnings.data);

      this.checkoutMethodsChartData = {
        datasets: [
          {
            data: checkoutMethods.data.data,
            backgroundColor: [
              '#086DE1',
              '#28a745',
              '#ffc107',
              '#6c757d',
              '#dc3545',
            ],
          },
        ],
        labels: checkoutMethods.data.labels,
      };
      this.sendMethodsChartData = {
        datasets: [
          {
            data: sendMethods.data.data,
            backgroundColor: [
              '#086DE1',
              '#28a745',
              '#ffc107',
              '#6c757d',
              '#dc3545',
            ],
          },
        ],
        labels: sendMethods.data.labels,
      };
      this.orderMethodsChartData = {
        datasets: [
          {
            data: orderMethods.data.data,
            backgroundColor: ['#086DE1', '#28a745'],
          },
        ],
        labels: orderMethods.data.labels,
      };
      this.orderTypesChartData = {
        datasets: [
          {
            data: orderTypes.data.data,
            backgroundColor: ['#086DE1', '#28a745'],
          },
        ],
        labels: orderTypes.data.labels,
      };
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα δεδομένα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep #bar-chart {
  max-width: 100%;
}
</style>
